import Image from 'next/image';
import React from 'react';

export const variantOfHero = {
  home: {
    listImg: [
      <Image
        key='home-left-mobile'
        src='/icons/home/mobile-left.png'
        alt='Home left mobile'
        width={156}
        height={133}
        className='absolute left-0 top-0 w-[9.75rem] 2xl:hidden'
        priority
      />,
      <Image
        key='home-right-mobile'
        src='/icons/home/mobile-right.png'
        alt='Home right mobile'
        width={156}
        height={133}
        className='absolute right-0 top-0 w-[9.75rem] 2xl:hidden'
        priority
      />,
      <Image
        key='home-desktop-left'
        src='/icons/home/desktop-left.png'
        alt='Home desktop left'
        width={514}
        height={460}
        className='absolute bottom-0 left-0 hidden 2xl:block'
        fetchPriority='high'
      />,
      <Image
        key='home-desktop-right'
        src='/icons/home/desktop-right.png'
        alt='Home destop right'
        width={526.49}
        height={460.59}
        className='absolute bottom-0 right-0 hidden 2xl:block'
        fetchPriority='high'
      />,
    ],
  },
  aboutUsCy: {
    listImg: [
      <Image
        key='about-cy-left-mobile'
        src='/icons/aboutUsCy/mobile-left.png'
        alt='Left image'
        width={156}
        height={108}
        className='absolute left-0 top-0 h-[8rem] w-[11.2rem] object-contain object-left 2xl:hidden'
        priority
      />,
      <Image
        key='about-cy-right-image'
        src='/icons/aboutUsCy/mobile-right.png'
        alt='Right Image'
        width={140}
        height={108}
        className='absolute right-0 top-0 h-[10rem] w-[14rem] object-contain object-right 2xl:hidden'
        priority
      />,
      <Image
        key='about-cy-desktop-left'
        src='/icons/aboutUsCy/desktop-left.png'
        alt='Left image desktop'
        width={316}
        height={620}
        className='absolute -top-5 left-0 hidden w-[24.5rem] 2xl:-left-5 2xl:block'
        priority
      />,
      <Image
        key='about-cy-desktop-right'
        src='/icons/aboutUsCy/desktop-right.png'
        alt='Desktop right image'
        width={444}
        height={620}
        className='absolute -right-17 top-0 hidden w-[34.5rem] 2xl:block'
        priority
      />,
    ],
    isFullyShow: true,
  },
  aboutUsMu: {
    listImg: [
      <Image
        src='/icons/aboutUsMu/mobile-left.png'
        key='about-mu-left-mobile'
        alt='Left image mobile'
        width={156}
        height={122}
        className='absolute left-0 top-0 h-[8rem] w-[11.2rem] object-contain object-left 2xl:hidden'
        priority
      />,
      <Image
        src='/icons/aboutUsMu/mobile-right.png'
        key='about-mu-right-mobile'
        alt='Right image mobile'
        width={140}
        height={168}
        className='absolute right-0 top-0 h-[10rem] w-[14rem] object-contain object-right 2xl:hidden'
        priority
      />,
      <Image
        src='/icons/aboutUsMu/desktop-left.png'
        key='about-mu-desktop-left'
        alt='Desktop left img'
        width={316}
        height={620}
        className='absolute -top-5 left-0 hidden w-[24.5rem] 2xl:-left-5 2xl:block'
        priority
      />,
      <Image
        src='/icons/aboutUsMu/desktop-right.png'
        key='about-desktop-right-image'
        alt='Desktop right image'
        width={444}
        height={620}
        className='absolute -right-17 top-0 hidden w-[34.5rem] 2xl:block'
        priority
      />,
    ],
    isFullyShow: true,
  },
  foreignExchange: {
    listImg: [
      <Image
        src='/icons/foreignExchange/mobile.png'
        key='foreignExchange-mobile'
        alt='Mobile Img'
        width={302}
        height={95}
        className='absolute left-1/2 top-0 w-full max-w-[19rem] -translate-x-1/2 lg:max-w-[13rem] 2xl:hidden'
        priority
      />,
      <Image
        src='/icons/foreignExchange/desktop-left.png'
        key='foreignExchange-left-img'
        alt='Left Image'
        width={319}
        height={500}
        className='absolute bottom-0 left-0 hidden 2xl:block 2xl:h-full 2xl:w-full 2xl:max-w-[24.1rem] 2xl:object-cover'
        priority
      />,
      <Image
        src='/icons/foreignExchange/desktop-right.png'
        key='foreignExchange-right-img'
        alt='Right Image'
        width={319}
        height={500}
        className='absolute bottom-0 right-0 hidden 2xl:block 2xl:h-full 2xl:w-full 2xl:max-w-[24.1rem] 2xl:object-cover'
        priority
      />,
    ],
  },

  equityIndices: {
    listImg: [
      <Image
        src='/icons/equityIndices/mobile.png'
        key='equityIndices-mobile'
        alt='Mobile img'
        width={302}
        height={95}
        className='absolute left-1/2 top-0 w-full max-w-[19rem] -translate-x-1/2 lg:max-w-[13rem] 2xl:hidden'
        priority
      />,
      <Image
        src='/icons/equityIndices/desktop-left.png'
        key='equityIndices-left-img'
        alt='Left image'
        width={319}
        height={500}
        className='absolute bottom-0 left-0 hidden 2xl:block 2xl:h-full 2xl:w-full 2xl:max-w-[24.1rem] 2xl:object-cover'
        priority
      />,
      <Image
        src='/icons/equityIndices/desktop-right.png'
        key='equityIndices-right-img'
        alt='Right image'
        width={319}
        height={500}
        className='absolute bottom-0 right-0 hidden 2xl:block 2xl:h-full 2xl:w-full 2xl:max-w-[24.1rem] 2xl:object-cover'
        priority
      />,
    ],
  },

  preciousMetals: {
    listImg: [
      <Image
        src='/icons/preciousMetals/mobile.png'
        key='preciousMetals-mobile'
        alt='Mobile img'
        width={302}
        height={95}
        className='absolute left-1/2 top-0 w-full max-w-[19rem] -translate-x-1/2 lg:max-w-[13rem] 2xl:hidden'
        priority
      />,
      <Image
        src='/icons/preciousMetals/desktop-left.png'
        key='preciousMetals-left-img'
        alt='Left img'
        width={319}
        height={500}
        className='absolute bottom-0 left-0 hidden 2xl:block 2xl:h-full 2xl:w-full 2xl:max-w-[24.1rem] 2xl:object-cover'
        priority
      />,
      <Image
        src='/icons/preciousMetals/desktop-right.png'
        key='preciousMetals-right'
        alt='Right img'
        width={319}
        height={500}
        className='absolute bottom-0 right-0 hidden 2xl:block 2xl:h-full 2xl:w-full 2xl:max-w-[24.1rem] 2xl:object-cover'
        priority
      />,
    ],
  },

  spotCommodities: {
    listImg: [
      <Image
        src='/icons/spotCommodities/mobile.png'
        key='spotCommodities-mobile'
        alt='Mobile img'
        width={302}
        height={95}
        className='absolute left-1/2 top-0 w-full max-w-[19rem] -translate-x-1/2 lg:max-w-[13rem] 2xl:hidden'
        priority
      />,
      <Image
        src='/icons/spotCommodities/desktop-left.png'
        key='spotCommodities-left'
        alt='Left img'
        width={319}
        height={500}
        className='absolute bottom-0 left-[2rem] hidden 2xl:block 2xl:h-full 2xl:w-full 2xl:max-w-[24.1rem] 2xl:object-contain'
        priority
      />,
      <Image
        src='/icons/spotCommodities/desktop-right.png'
        key='spotCommodities-right'
        alt='Right Img'
        width={319}
        height={500}
        className='absolute bottom-0 right-0 hidden 2xl:block 2xl:h-full 2xl:w-full 2xl:max-w-[24.1rem] 2xl:object-cover'
        priority
      />,
    ],
  },

  cryptoCFD: {
    listImg: [
      <Image
        src='/icons/cryptoCFD/mobile.png'
        key='cryptoCFD-mobile'
        alt='Mobile img'
        width={302}
        height={95}
        className='absolute left-1/2 top-0 w-full max-w-[19rem] -translate-x-1/2 lg:max-w-[13rem] 2xl:hidden'
        priority
      />,
      <Image
        src='/icons/cryptoCFD/desktop-left.png'
        key='cryptoCFD-left'
        alt='Left Img'
        width={319}
        height={500}
        className='absolute bottom-0 left-0 hidden 2xl:block 2xl:h-full 2xl:w-full 2xl:max-w-[24.1rem] 2xl:object-cover'
        priority
      />,
      <Image
        src='/icons/cryptoCFD/desktop-right.png'
        key='cryptoCFD-right'
        alt='Right Img'
        width={319}
        height={500}
        className='absolute bottom-0 right-0 hidden 2xl:block 2xl:h-full 2xl:w-full 2xl:max-w-[24.1rem] 2xl:object-cover'
        priority
      />,
    ],
  },

  ndfAsCFDs: {
    listImg: [
      <Image
        src='/icons/ndfAsCFDs/mobile.png'
        key='ndfAsCFDs-mobile'
        alt='Mobile img'
        width={302}
        height={95}
        className='absolute left-1/2 top-0 w-full max-w-[19rem] -translate-x-1/2 lg:max-w-[13rem] 2xl:hidden'
        priority
      />,
      <Image
        src='/icons/ndfAsCFDs/desktop-left.png'
        key='ndfAsCFDs-left'
        alt='Left Img'
        width={319}
        height={500}
        className='absolute bottom-0 left-0 hidden 2xl:block 2xl:h-full 2xl:w-full 2xl:max-w-[24.1rem] 2xl:object-cover'
        priority
      />,
      <Image
        src='/icons/ndfAsCFDs/desktop-right.png'
        key='ndfAsCFDs-right'
        alt='Right Img'
        width={319}
        height={500}
        className='absolute bottom-0 right-0 hidden 2xl:block 2xl:h-full 2xl:w-full 2xl:max-w-[24.1rem] 2xl:object-cover'
        priority
      />,
    ],
  },

  'traders-room': {
    listImg: [
      <Image
        src='/icons/hero-section-traders-room-1.svg'
        key='traders-room-left-mobile'
        alt='left mobile'
        width={223}
        height={179}
        className='absolute left-[1rem] top-[2.125rem] w-[7rem] lg:left-1 lg:top-1 lg:w-[8.625rem] 2xl:left-[3.125rem] 2xl:top-[3.125rem] 2xl:w-[14rem]'
        priority
      />,
      <Image
        src='/icons/hero-section-traders-room-2.svg'
        key='traders-room-right-mobile'
        alt='right mobile'
        width={172}
        height={168}
        className='absolute right-1 top-1 w-[7.5rem] lg:right-0 lg:top-7 lg:w-[7.25rem] 2xl:right-[4.75rem] 2xl:top-[4.75rem] 2xl:w-[10.75rem]'
        priority
      />,
      <Image
        src='/icons/hero-section-traders-room-3.svg'
        key='traders-room-dekstop-left'
        alt='left Desktop'
        width={289}
        height={249}
        className='absolute hidden lg:-bottom-5 lg:right-[4.375rem] lg:block lg:w-[10rem] 2xl:bottom-[-3.5rem] 2xl:right-[10rem] 2xl:w-[18.125rem]'
        priority
      />,
      <Image
        src='/icons/hero-section-traders-room-4.svg'
        key='traders-room-right-desktop'
        alt='Right Img'
        width={195}
        height={200}
        className='absolute hidden lg:bottom-4 lg:left-[5rem] lg:block lg:w-[7.5rem] 2xl:bottom-3 2xl:left-[14.0625rem] 2xl:w-[12.25rem]'
        priority
      />,
    ],
  },
};
