import { Button, EButtonSize, TAction } from '@front/shared/ds';
import { getGaId } from '@front/shared/utils';
import Link from 'next/link';
import React from 'react';

export type THeroSectionProps = {
  actions: TAction[];
  title: string;
  subtitle?: string;
};

const HeroSection: React.FC<THeroSectionProps> = props => {
  const { actions = [], title, subtitle } = props;

  return (
    <section>
      <div className='grid items-center rounded-3xl bg-surface-100 p-4 pt-[8.25rem] lg:h-[22.25rem] lg:p-8 2xl:h-[31.25rem]'>
        <div className='z-10 mt-auto grid gap-8 lg:mt-0 lg:auto-rows-max lg:gap-12'>
          <div className='mx-auto flex flex-col-reverse gap-4 text-center lg:w-7/12 2xl:max-w-[51rem]'>
            <h1 className='text-6xl font-semibold leading-relaxed text-interface-1000 2xl:text-9xl 2xl:leading-tight'>
              {title}
            </h1>
            {subtitle && (
              <h2 className='text-lg font-medium text-brand-500'>{subtitle}</h2>
            )}
          </div>
          <div className='mx-auto flex w-full max-w-[22rem] flex-col justify-center gap-4 empty:hidden 2xl:max-w-[50rem] 2xl:flex-row'>
            {actions.map((action, index) => (
              <React.Fragment key={index}>
                <div className='grid 2xl:grid'>
                  {action.type === 'button' && (
                    <Button
                      {...action.props}
                      size={EButtonSize.Large}
                      data-ga-id={getGaId(
                        action.dataGaId || action.text,
                        'hero',
                      )}
                    />
                  )}
                  {action.type === 'link' && (
                    <Link
                      className='flex items-center justify-center gap-2.5 rounded-2xl bg-brand-500 px-10 py-5 text-center text-base font-medium leading-tight text-brand-50/90 transition last:border last:border-brand-500 last:bg-transparent last:text-brand-500 hover:bg-brand-550 hover:text-brand-50'
                      {...action.props}
                      data-ga-id={getGaId(
                        action.dataGaId || action.text,
                        'hero',
                      )}
                    />
                  )}
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
