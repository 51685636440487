import clsx from 'clsx';
import React from 'react';

export interface IHeroBackgroundProps {
  children: React.ReactNode;
  listImg: React.JSX.Element[];
  isFullyShow?: boolean;
}

const HeroBackground: React.FC<IHeroBackgroundProps> = ({
  isFullyShow,
  children,
  listImg,
}) => {
  return (
    <section className='relative'>
      <div
        className={clsx(
          'pointer-events-none absolute inset-0',
          !isFullyShow && 'overflow-hidden',
        )}
      >
        {...listImg}
      </div>
      {children}
    </section>
  );
};
export default HeroBackground;
